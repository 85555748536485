// vue
import { ref } from 'vue'

// nuxt
import { useNuxtApp } from '#app'

// pinia
import { defineStore } from 'pinia'

// types
import type { Nullable, Student } from '@revolutionprep/types'
import type { NitroFetchOptions } from 'nitropack'

export const useStudentStore = defineStore('student', () => {
  // fetch repositories
  const { $students } = useNuxtApp()

  // state
  const student = ref<Nullable<Student>>(null)

  // actions
  async function show (
    id: number,
    config?: NitroFetchOptions<string>
  ) {
    const { student: _student } =
      await $students.show<{ student: Student }>(id, config)
    student.value = _student
    return student
  }

  async function update (
    id: number,
    payload: { [key: string]: any },
    config?: NitroFetchOptions<string>
  ) {
    const { student: _student } =
      await $students.update< { student: Student }>(id, payload, config)
    student.value = _student
    return student
  }

  return {
    student,
    show,
    update
  }
})
